<template>
 <div>
   <div class="politics">
     <h1><b>RealClear Politics</b></h1>
   </div>
   <img class="full-size-bg-img" src="/images/SearchRockitNewsFeed.jpg" alt="banner">
 </div>
</template>

<script>
export default {
name: "Newsfeed"
}
</script>

<style scoped>

.politics {
  background-color: #2f5395;
  color: white;
  margin-top: 25px;
  padding-left: 135px;
  padding-top: 15px;
  padding-bottom: 15px;
}

</style>